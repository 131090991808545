import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const LoadingSpinner = (): React.ReactNode => {
  return (
    <div className="text-center mt-5">
      <FontAwesomeIcon icon={faCircleNotch} className="fa-spin fa-lg" />
    </div>
  );
};

export default LoadingSpinner;
