// @flow
import * as React from 'react';
import { useLocation } from 'react-router-dom';

type Props = {
  children: React.Node;
}

const ScrollToTop = (props: Props): React.Node => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [location]);

  return props.children;
};

export default ScrollToTop;
