import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'hise-components';
import useAppSelector from '../../hooks/useAppSelector';
import { addAmdsToBillingPolicy } from '../../redux/billingSlice';
import { executeCertificate } from '../../redux/certificateExecutionSlice';
import { areAllReqsMet } from '../../components/Guest/ReproduceCertificate/helpers';
import type { Certificate, WorkspaceCreationRequirements } from '../../@types';
import text from './text.json';

const Detail = ({ label, detail }: { label: string, detail: string }) => {
  return (
    <li key={label}>
      <FontAwesomeIcon icon={faCircleCheck} className="me-2" color="green" />

      <span className="fw-bold">
        {label}
        :
        {' '}
      </span>

      <span>
        {detail}
      </span>
    </li>
  );
};

const generateWorkspace = async (
  dispatch: Function,
  navigate: Function,
) => {
  await dispatch(executeCertificate())
    .then((response) => {
      if (!response?.error?.message) navigate('/confirmation');
    });
};

const createGuestUserWorkspace = async (
  dispatch: Function,
  navigate: Function,
) => {
  const billingPolicyResponse = await dispatch(addAmdsToBillingPolicy()).unwrap();

  if (billingPolicyResponse.ok) {
    await generateWorkspace(dispatch, navigate);
  }
};

const createWorkspace = async (
  userType: string,
  dispatch: Function,
  navigate: Function,
) => {
  if (userType === 'regular') {
    await generateWorkspace(dispatch, navigate);
  } else {
    await createGuestUserWorkspace(dispatch, navigate);
  }
};

const Finish = ({ requirements, certificate }: {
  requirements: WorkspaceCreationRequirements,
  certificate: Certificate,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useAppSelector((state) => state.user.userInfo);
  const selectedBillingId = useAppSelector((state) => state.billing.selectedBillingId);

  const [loading, setLoading] = useState(false);

  const handleStartWorkspace = async () => {
    setLoading(true);

    try {
      await createWorkspace(userInfo.userType, dispatch, navigate);
    } catch (error) {
      toast.error(error);
    }

    setLoading(false);
  };

  return (
    <div>
      <h3>{text.finish.header}</h3>

      <div className="my-4">
        <h5>
          {text.finish.subheader}
          <br />
        </h5>
        <h6 className="fw-normal fst-italic">
          {certificate?.title}
        </h6>
      </div>

      {selectedBillingId && <p className="fw-bold">{text.finish.detailHeader}</p>}

      <ul className="mt-4 mb-4 list-unstyled">
        <Detail label="Email" detail={userInfo.email} />
        {selectedBillingId && <Detail label="Billing ID" detail={selectedBillingId} />}
      </ul>

      <div className="text-center p-4">
        <Button
          id="kick-repro-btn"
          color="primary"
          className="text-white"
          disabled={!areAllReqsMet(userInfo.userType, requirements)}
          onClick={handleStartWorkspace}
        >
          {loading && <FontAwesomeIcon icon={faCircleNotch} className="fa-spin fa-lg me-2" />}
          {text.finish.kickoffButtonLabel}
        </Button>
      </div>
    </div>
  );
};

export default Finish;
