import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  resetErrorBoundary: Function,
  btnText: string,
  btnTo: string,
  title?: string,
};

const ErrorFallback = (props: Props): React.Node => {
  const navigate = useNavigate();

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-5 offset-2">
          <h3>{ props.title }</h3>
          <button
            type="button"
            className="hise-btn"
            onClick={() => {
              props.resetErrorBoundary();
              navigate(props.btnTo);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
            { props.btnText }
          </button>
        </div>
      </div>
    </div>
  );
};

ErrorFallback.defaultProps = {
  title: 'Something went wrong',
};

export default ErrorFallback;
