/* eslint-disable no-await-in-loop */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UserState, AppState } from '../hooks/useAppSelector';

const internalInitialState: UserState = {
  isLoading: false,
  userInfo: {
    userId: '',
    isRecognized: false,
    userType: '',
    email: '',
    workspaces: [],
  },
  isAuthenticated: false,
  credentials: {
    clientId: '',
    credential: '',
  },
  accountId: '',
  googleAccessToken: '',
};

interface GetUserInfoData {
  status: number,
  url: string,
  userInfo: UserState['userInfo'],
}

const userSlice = createSlice({
  name: 'userSlice',
  initialState: internalInitialState,
  reducers: {
    resetUserState: () => {
      return internalInitialState;
    },
    setUserInfo: (state, action) => {
      const { userInfo } = action.payload;
      state.userInfo = userInfo;
      state.isAuthenticated = true;
    },
    setUserAuthentication: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setCredentials: (state, action) => {
      const { credentials } = action.payload;
      state.credentials = credentials;
      state.isAuthenticated = true;
    },
    setUserType: (state, action) => {
      const { userType } = action.payload;
      state.userInfo.userType = userType;
    },
    setAccountId: (state, action) => {
      const { accountId } = action.payload;
      state.accountId = accountId;
    },
    setUserId: (state, action) => {
      const { userId } = action.payload;
      state.userInfo.userId = userId;
    },
    setGoogleAccessToken: (state, action) => {
      const { token } = action.payload;
      state.googleAccessToken = token;
    },
    removeGoogleAccessToken: (state) => {
      state.googleAccessToken = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      const { userInfo } = action.payload as GetUserInfoData;

      state.isLoading = false;
      state.userInfo = userInfo;
      state.isAuthenticated = true;
    });
    builder.addCase(getUserInfo.rejected, (state) => {
      state.isLoading = false;
      state.isAuthenticated = false;
    });
  },
});

const publicUserInfoUrl = 'publication/workspace/userInfo';

export const getUserInfo = createAsyncThunk<GetUserInfoData>(
  'getUserInfo',
  async (_, { getState }) => {
    const { user } = getState() as AppState;

    const userInfoResponse = await fetch(
      `${process.env.REACT_APP_PUBLIC_HISE_BASE_URL}/${publicUserInfoUrl}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Proxy-Authorization': `Bearer ${user.credentials.clientId}`,
          Authorization: `Bearer ${user.credentials.credential}`,
        },
      },
    );

    const userInfo = await userInfoResponse.json();

    return {
      status: userInfoResponse.status,
      url: userInfoResponse.url,
      userInfo,
    } as GetUserInfoData;
  },
);

export const {
  resetUserState,
  setUserInfo,
  setUserAuthentication,
  setCredentials,
  setUserType,
  setAccountId,
  setUserId,
  setGoogleAccessToken,
  removeGoogleAccessToken,
} = userSlice.actions;

export const userReducer = userSlice.reducer;
