/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { AppState, BillingState } from '../hooks/useAppSelector';

import { addAMDSOrCreateAdminBinding } from '../utils';

const internalInitialState: BillingState = {
  isLoading: false,
  errors: [],
  availableBillingIds: [],
  selectedBillingId: '',
};

const billingSlice = createSlice({
  name: 'userSlice',
  initialState: internalInitialState,
  reducers: {
    resetBillingState: () => {
      return internalInitialState;
    },
    resetAvailableBillingIds: (state) => {
      state.availableBillingIds = [];
    },
    setSelectedBillingId: (state, action) => {
      const { billingId } = action.payload;
      state.selectedBillingId = billingId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addAmdsToBillingPolicy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addAmdsToBillingPolicy.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(addAmdsToBillingPolicy.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getUserBillingIds.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserBillingIds.fulfilled, (state, action) => {
      const { billingIds } = action.payload;
      state.isLoading = false;
      state.availableBillingIds = billingIds;
    });
    builder.addCase(getUserBillingIds.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const getBillingPolicies = createAsyncThunk(
  'getBillingPolicies',
  async (_, { getState }) => {
    const { billing, user } = getState() as AppState;
    const { selectedBillingId } = billing;

    const googleToken = user.googleAccessToken;

    const res = await fetch(
      `https://cloudbilling.googleapis.com/v1/billingAccounts/${selectedBillingId}:getIamPolicy`,
      {
        method: 'GET',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${googleToken}`,
        },
      },
    );
    if (!res.ok) {
      toast.error('Unable to get current billing policies. Please have your Google Cloud Administrator grant you Billing Viewer permissions');
    }

    const policy = await res.json();
    return policy;
  },
);

export const addAmdsToBillingPolicy = createAsyncThunk(
  'addAmdsToBillingPolicy',
  async (_, { getState, dispatch }) => {
    const { billing, user } = getState() as AppState;
    const { selectedBillingId } = billing;
    const googleToken = user.googleAccessToken;

    const existingPolicy = await dispatch(getBillingPolicies()).unwrap();

    const amdsEmail = process.env.REACT_APP_AMDS_SA_EMAIL;

    if (!amdsEmail) {
      toast('REACT_APP_AMDS_SA_EMAIL is not defined');
    }

    const amdsMember = `serviceAccount:${amdsEmail}`;

    const isAMDSAlreadyBillingAdmin = existingPolicy.bindings.filter((binding) => {
      return binding.role === 'roles/billing.admin' && binding.members.indexOf(amdsMember) > -1;
    }).length;

    if (isAMDSAlreadyBillingAdmin) {
      return ({ ok: true });
    }

    const requestBody = {
      policy: {
        ...existingPolicy,
        bindings: addAMDSOrCreateAdminBinding(existingPolicy.bindings || [], amdsMember),
      },
    };

    const linkBillingResponse = await fetch(
      `https://cloudbilling.googleapis.com/v1/billingAccounts/${selectedBillingId}:setIamPolicy`,
      {
        method: 'POST',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${googleToken}`,
        },
        body: JSON.stringify(requestBody),
      },
    );

    if (!linkBillingResponse.ok) {
      toast.error('Unable to link billing ID. You may not have permission to give billing access, please contact your Google Cloud administrator if this is the case and try again.');
    }

    return linkBillingResponse;
  },
);

export const getUserBillingIds = createAsyncThunk(
  'getUserBillingIds',
  async (_, { getState, dispatch }) => {
    const { user } = getState() as AppState;
    const googleToken = user.googleAccessToken;

    const billingIdsResponse = await fetch(
      'https://cloudbilling.googleapis.com/v1/billingAccounts',
      {
        method: 'GET',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${googleToken}`,
        },
      },
    );

    if (!billingIdsResponse.ok) {
      const err = await billingIdsResponse.text();
      dispatch(resetAvailableBillingIds());
      toast(err);
    }
    const billingIds = await billingIdsResponse.json();
    return { billingIds: billingIds.billingAccounts.filter((id) => id.open) };
  },
);

export const {
  resetBillingState,
  setSelectedBillingId,
  resetAvailableBillingIds,
} = billingSlice.actions;

export const billingReducer = billingSlice.reducer;
