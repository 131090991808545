import React from 'react';
import { Button } from 'hise-components';
import text from './text.json';

const AlreadyCreated = () => {
  return (
    <div>
      <h3 className="text-primary">
        {text.alreadyCreated.header}
      </h3>

      <p>
        {text.alreadyCreated.lead}
      </p>

      <p className="lead text-center mt-5">
        <Button
          id="go-btn"
          color="primary"
          className="text-white fw-bold"
          target="_blank"
          href={process.env.REACT_APP_HISE_BASE_URL}
        >
          Go to Workspaces
        </Button>
      </p>
    </div>
  );
};

export default AlreadyCreated;
