import React from 'react';
import text from './text.json';

const Start = () => {
  return (
    <div className="mb-4">
      <h3 className="text-primary">
        {text.guestSignUpHero.header}
      </h3>
      <p className="lead fst-italic">
        {text.guestSignUpHero.paragraph_1}
      </p>
      <p>
        {text.guestSignUpHero.paragraph_2}
        <ul>
          {text.guestSignUpHero.paragraph_2_list.map(((li) => (
            <li key={li}>{li}</li>
          )))}
        </ul>
      </p>
      <p>
        {text.guestSignUpHero.paragraph_3}
      </p>
    </div>
  );
};

export default Start;
