import React from 'react';
import { isEmpty } from 'ramda';
import { Link } from 'react-router-dom';
import { usePublications } from '../../state/PublicationsContext';
import { useDataApps } from '../../state/DataAppsContext';
import text from './text.json';

const HeroLinks = (): React.ReactNode => {
  const { publications, config } = usePublications();
  const { dataApps } = useDataApps();

  if (isEmpty(publications)) return null;

  const getLink = (item) => {
    if (item === 'dataApps') return config[item].url;

    if (!publications[item] || !publications[item].length) return '/publications';

    return `/publications/${config[item].url}`;
  };

  return (
    <div className="list">
      {text.listItems.map((item) => {
        if (item === 'dataApps' && !dataApps.length) return null;

        return (
          <h3 key={item}>
            <Link to={getLink(item)}>
              {config[item].heroLabel}
            </Link>
          </h3>
        );
      })}
    </div>
  );
};

const HeroHeader = (): React.ReactNode => {
  const { completed } = usePublications();

  if (!completed) return null;

  return (
    <div className="hero">
      <div className="contents">
        <h1 className="header">
          {text.header}
        </h1>
        <h2>
          {text.subheader}
        </h2>
      </div>

      <HeroLinks />
    </div>
  );
};

export default HeroHeader;
