import React from 'react';
import ErrorFallback from '../components/ErrorFallback';
import usePageTitle from '../hooks/usePageTitle';

type Props = {
  resetErrorBoundary: Function,
};

const ActivePublicationFallback = (props: Props): React.ReactNode => {
  usePageTitle('Something went wrong  - Human Immune System Explorer');

  return (
    <ErrorFallback
      btnTo="/publications"
      btnText="Back to publications"
      resetErrorBoundary={props.resetErrorBoundary}
    />
  );
};

export default ActivePublicationFallback;
