import { useEffect, useState } from 'react';
import * as R from 'ramda';
import { useParams } from 'react-router-dom';
import useAppSelector from '../../hooks/useAppSelector';
import NavigationButtons from '../../components/Guest/ReproduceCertificate/NavigationButtons';
import AlreadyCreated from './AlreadyCreated';
import Start from './Start';
import GoogleAuthentication from './GoogleAuthentication';
import TermsAndPayment from './TermsAndPayment';
import BillingPermission from './BillingPermission';
import Finish from './Finish';
import ProgressBar from '../../components/Guest/ReproduceCertificate/ProgressBar';
import type { Certificate, WorkspaceCreationRequirements } from '../../@types';

const CreateWorkspaceContent = ({
  step, requirements, setRequirements, certificate,
}: {
  step: number,
  requirements: WorkspaceCreationRequirements,
  setRequirements: Function,
  certificate: Certificate,
}) => {
  if (R.isEmpty(certificate)) return null;

  const handleRequirements = (item, status) => setRequirements({ ...requirements, [item]: status });

  switch (step) {
    case -1:
      return <AlreadyCreated />;

    case 0:
      return <Start />;

    case 1:
      return (
        <GoogleAuthentication
          requirements={requirements}
          handleRequirements={handleRequirements}
        />
      );

    case 2:
      return <TermsAndPayment requirements={requirements} onCheck={handleRequirements} />;

    case 3:
      return (
        <BillingPermission
          requirements={requirements}
          handleRequirements={handleRequirements}
        />
      );

    case 4:
      return (
        <Finish requirements={requirements} certificate={certificate} />
      );

    default:
      return <Start />;
  }
};

const CreateWorkspace = () => {
  const { certificateId } = useParams();
  const { workspaces } = useAppSelector((state) => state.user.userInfo);

  const [certificate, setCertificate] = useState<Certificate>({} as Certificate);

  const [step, setStep] = useState(0);
  const [requirements, setRequirements] = useState<WorkspaceCreationRequirements>({
    agreeToTerms: false,
    agreeToPayment: false,
    hasGoogleAccount: false,
    agreeToAuthorizeBilling: false,
    isBillingIdValid: false,
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_HISE_BASE_URL}/public/certificate/${certificateId}`)
      .then(async (response) => {
        const certificateData = await response.json();
        setCertificate(certificateData);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, [certificateId]);

  const hasWorkspace = R.find(R.propEq(certificate.certificateId, 'certificateGuid'))(workspaces || []);

  return (
    <div className="container my-5 px-lg-5" style={{ minHeight: '85vh' }}>
      <div className="container" style={{ minHeight: '60vh' }}>
        <div className="mb-5">
          <h2 className="text-dark">Create Your Workspace</h2>
          <section>
            <em className="small">
              {certificate?.title}
            </em>
          </section>
        </div>

        <CreateWorkspaceContent
          step={step}
          requirements={requirements}
          setRequirements={setRequirements}
          certificate={certificate}
        />
      </div>

      <div className="pb-5">
        <div className="my-4">
          <ProgressBar step={step} />
        </div>

        <NavigationButtons
          step={step}
          setStep={setStep}
          requirements={requirements}
          hasWorkspace={hasWorkspace}
        />
      </div>
    </div>
  );
};

export default CreateWorkspace;
