/* eslint-disable no-promise-executor-return */
import type { Certificate, Publication } from './@types';
import { LinkItem } from './state/NavbarContext';

export const sleep = (ms) => new Promise((res) => setTimeout(res, ms));

export const addAMDSOrCreateAdminBinding = (bindings, amdsMember) => {
  const existingAdminBinding = bindings.filter((binding) => binding.role === 'roles/billing.admin');

  if (existingAdminBinding) {
    return bindings.reduce((newBindings, existingBinding) => {
      if (existingBinding.role === 'roles/billing.admin') {
        if (existingBinding.members.indexOf(amdsMember) > -1) {
          newBindings.push(existingBinding);
        } else {
          existingBinding.members.push(amdsMember);
          newBindings.push(existingBinding);
        }
      } else {
        newBindings.push(existingBinding);
      }
      return newBindings;
    }, []);
  }

  return [...bindings, { role: 'roles/billing.admin', members: [amdsMember] }];
};

const getNavigationLabel = (title: string) => {
  if (title.length <= 20) return title;

  return `${title.substring(0, 20)}...`;
};

export const getPublicationNavItems = (
  publication: Publication,
  certificate: Certificate,
) => {
  if (!publication) return [];

  const getRoute = (route) => (
    `explore/${publication.id}/${route}`
  );

  const tabOptions: Array<LinkItem> = [
    { key: getRoute(''), label: 'Publication' },
  ];

  if (certificate && Object.keys(certificate).length) {
    tabOptions.push({ key: getRoute('reproducibility'), label: 'Reproducibility' });
  }

  if (publication.visualizations?.length) {
    if (publication.visualizations.length > 1) {
      tabOptions.push({
        key: 'visualizations',
        label: 'Visualizations',
        isDropdown: true,
        dropdownItems: publication.visualizations.map((visualization) => ({
          key: getRoute(`visualization/${visualization.visualizationId}`),
          label: getNavigationLabel(visualization.title),
        })),
      });
    } else {
      tabOptions.push({
        key: getRoute(`visualization/${publication.visualizations[0].visualizationId}`),
        label: 'Visualization',
      });
    }
  }

  if (publication.fileSets?.length) {
    if (publication.fileSets.length > 1) {
      tabOptions.push({
        key: 'fileSets',
        label: 'Data Sets',
        isDropdown: true,
        dropdownItems: publication.fileSets.map((fileSet) => ({
          key: getRoute(`dataset/${fileSet.fileSetId}`),
          label: getNavigationLabel(fileSet.title),
        })),
      });
    } else {
      tabOptions.push({
        key: getRoute(`dataset/${publication.fileSets[0].fileSetId}`),
        label: 'Data Set',
      });
    }
  }

  if (publication.ideInstances?.length) {
    if (publication.ideInstances.length > 1) {
      tabOptions.push({
        key: 'notebooks',
        label: 'Analysis Notebooks',
        isDropdown: true,
        dropdownItems: publication.ideInstances.map((ideInstance) => ({
          key: getRoute(`notebook/${ideInstance.ideObjectId}`),
          label: getNavigationLabel(ideInstance.title),
        })),
      });
    } else {
      tabOptions.push({
        key: getRoute(`notebook/${publication.ideInstances[0].ideObjectId}`),
        label: 'Analysis Notebook',
      });
    }
  }

  tabOptions.push({ key: getRoute('citation'), label: 'Citation +' });

  return tabOptions;
};
