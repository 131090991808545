import React from 'react';
import { Button } from 'hise-components';
import ListCards from '../../components/ListCards';
import HeroHeader from '../../components/HeroHeader';
import usePageTitle from '../../hooks/usePageTitle';
import { useDataApps } from '../../state/DataAppsContext';
import { usePublications } from '../../state/PublicationsContext';
import './style.scss';

const OpenScienceStories = () => {
  return (
    <div className="container-wrapper">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-7 my-auto me-2 text-center text-md-end">
            <h2>
              Open Science Stories
              <br />
              from the Allen Institute
            </h2>

            <p>
              The Allen Institute openly shares datasets, software, protocols, lesson plans,
              {' '}
              modeling tools, machine learning algorithms, cell lines, plasmids – and more – to
              {' '}
              accelerate bioscience research and discovery worldwide.
            </p>
            <p className="fst-italic">
              Have you used our open science resources to support your research or curriculum?
              {' '}
              We want to hear from you!
            </p>

            <Button
              id="open-science-share-btn"
              className="hise-btn btn-secondary"
              href="https://alleninstitute.org/opensciencestories/"
              target="_blank"
            >
              Share Your Story
            </Button>
          </div>

          <img
            className="col-11 col-sm-8 col-md-4 pt-4 pt-lg-0"
            src="/open-science-stories-image.jpg"
            alt="scientist looking at results"
          />
        </div>
      </div>
    </div>
  );
};

const LatestPublications = () => {
  const { publications, completed, error } = usePublications();

  if (!completed || error) return null;

  return (
    <div className="container-wrapper">
      <div className="container">
        <h2>Latest Publications</h2>

        <ListCards items={publications.all.slice(0, 3)} />

        <div className="text-center">
          <Button
            id="view-more-btn"
            to="/publications"
            color="primary"
            className="mt-4 hise-btn"
          >
            View More
          </Button>
        </div>
      </div>
    </div>
  );
};

const LatestDataApps = () => {
  const { dataApps } = useDataApps();

  if (!dataApps.length) return null;

  return (
    <div className="container-wrapper">
      <div className="container">
        <h2>Latest Data Apps</h2>

        <ListCards items={dataApps.slice(0, 3)} />

        <div className="text-center">
          <Button
            id="view-more-btn"
            to="/data-apps"
            color="primary"
            className="mt-4 hise-btn"
          >
            View More
          </Button>
        </div>

      </div>
    </div>
  );
};

const LandingPage = (): React.ReactNode => {
  usePageTitle('Human Immune System Explorer');

  return (
    <div className="landing">
      <HeroHeader />

      <div className="body">
        <LatestDataApps />
        <LatestPublications />
        <OpenScienceStories />
      </div>
    </div>
  );
};

export default LandingPage;
