import React, { useEffect } from 'react';
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import LoadingSpinner from '../../../components/LoadingSpinner';
import { getUserBillingIds, setSelectedBillingId } from '../../../redux/billingSlice';
import text from '../text.json';

export const AvailableBillingIds = ({ handleRequirements }: {
  handleRequirements: Function,
}) => {
  const dispatch = useAppDispatch();

  const { selectedBillingId } = useAppSelector((state) => state.billing);
  const userEmail = useAppSelector((state) => state.user.userInfo.email);
  const googleAccessToken = useAppSelector((state) => state.user.googleAccessToken);
  const billingLoading = useAppSelector((state) => state.billing.isLoading);
  const availableBillingIds = useAppSelector((state) => state.billing.availableBillingIds);

  useEffect(() => {
    dispatch(getUserBillingIds());
  }, [userEmail, googleAccessToken]);

  useEffect(() => {
    if (selectedBillingId) handleRequirements('isBillingIdValid', true);
  }, []);

  const handleBillingSelect = (e) => {
    if (e.target.value) {
      handleRequirements('isBillingIdValid', true);
      dispatch(setSelectedBillingId({ billingId: e.target.value }));
    }
  };

  if (billingLoading) return <LoadingSpinner />;

  const getBillingIdOptions = () => {
    const options = [
      <option key="" value="">
        {text.billingIdSelection.availableBillingIds.defaultOptionLabel}
      </option>,
    ];

    if (!availableBillingIds?.length) return options;

    availableBillingIds.forEach((availableBillingId) => {
      const billingId = availableBillingId.name.split('/')[1];

      options.push(
        <option
          key={availableBillingId.name}
          value={billingId}
        >
          {`${availableBillingId.displayName}: ${billingId}`}
        </option>,
      );
    });

    return options;
  };

  if (!availableBillingIds.length) {
    return (
      <p>
        {text.billingIdSelection.availableBillingIds.noFoundBillingIdsBanner}
      </p>
    );
  }

  return (
    <div>
      <h6>{text.billingIdSelection.availableBillingIds.header}</h6>

      <select
        name="billingIdSelect"
        id="billingIdSelect"
        className="p-2"
        value={selectedBillingId}
        onChange={handleBillingSelect}
        onClick={handleBillingSelect}
      >
        {getBillingIdOptions()}
      </select>
    </div>

  );
};
