import React from 'react';

const ProgressBar = ({ step }: { step: number }) => {
  const volume = (step / 5) * 100;

  return (
    <div className="progress w-100">
      <div
        className="progress-bar bg-secondary"
        role="progressbar"
        style={{ width: `${volume}%` }}
        aria-valuenow={25}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
  );
};

export default ProgressBar;
