import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { hasGrantedAllScopesGoogle, useGoogleLogin } from '@react-oauth/google';
import { Button } from 'hise-components';
import useAppSelector from '../../../hooks/useAppSelector';
import Status from '../../../components/Guest/ReproduceCertificate/Status';
import { NewBillingId } from './NewBillingId';
import { AvailableBillingIds } from './AvailableBillingIds';
import { setSelectedBillingId } from '../../../redux/billingSlice';
import { setGoogleAccessToken } from '../../../redux/userSlice';
import text from '../text.json';
import type { WorkspaceCreationRequirements } from '../../../@types';

const SelectBillingId = ({ handleRequirements, requirements }: {
  requirements: WorkspaceCreationRequirements,
  handleRequirements: Function,
}) => {
  const dispatch = useDispatch();
  const googleAccessToken = useAppSelector((state) => state.user.googleAccessToken);

  const [useExistingBillingIds, setUseExistingBillingIds] = useState(false);

  useEffect(() => {
    handleRequirements('isBillingIdValid', false);
    dispatch(setSelectedBillingId({ billingId: '' }));
  }, [useExistingBillingIds]);

  if (!googleAccessToken) return null;

  return (
    <div className="d-flex flex-column">
      <div className="mb-4">
        <label htmlFor="useExistingBillingIds" className="d-flex align-items-center">
          <input
            type="radio"
            id="useExistingBillingIds"
            checked={useExistingBillingIds}
            onClick={() => setUseExistingBillingIds(!useExistingBillingIds)}
          />
          <span className="m-0 ms-2">{text.billingIdSelection.radioLabels.existingId}</span>
        </label>

        <label htmlFor="useDifferentBillingId" className="d-flex align-items-center">
          <input
            type="radio"
            id="useDifferentBillingId"
            checked={!useExistingBillingIds}
            onClick={() => setUseExistingBillingIds(!useExistingBillingIds)}
          />
          <span className="m-0 ms-2">{text.billingIdSelection.radioLabels.differentId}</span>
        </label>
      </div>

      <div className="mb-4">
        {useExistingBillingIds && (
          <AvailableBillingIds handleRequirements={handleRequirements} />
        )}

        {!useExistingBillingIds && (
          <NewBillingId requirements={requirements} handleRequirements={handleRequirements} />
        )}
      </div>
    </div>
  );
};

const scope = 'https://www.googleapis.com/auth/cloud-billing';

const BillingPermission = ({ handleRequirements, requirements }: {
  requirements: WorkspaceCreationRequirements,
  handleRequirements: Function,
}) => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user);

  const getToken = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      if (tokenResponse && tokenResponse.access_token) {
        if (hasGrantedAllScopesGoogle(tokenResponse, scope)) {
          dispatch(setGoogleAccessToken({ token: tokenResponse.access_token }));
          handleRequirements('agreeToAuthorizeBilling', true);
        } else {
          toast(text.billingPermissions.toasts.declinedBillingPermissions);
        }
      } else {
        toast(text.billingPermissions.toasts.requestFailed);
      }
    },
    flow: 'implicit',
    prompt: 'select_account',
    scope,
  });

  if (user.userInfo.userType === 'regular') {
    return (
      <div>
        <h2>
          {text.billingPermissions.regularUserBanner}
        </h2>
      </div>
    );
  }

  return (
    <div>
      <h3>{text.billingPermissions.header}</h3>

      <div className="my-2">
        {text.billingPermissions.paragraph_1}
      </div>

      <div className="my-2 fw-bold">
        {text.billingPermissions.paragraph_2}
      </div>

      <div className="mb-4">
        <Link
          to="/support/billing"
          className="text-warning"
          target="_blank"
          rel="noopener noreferrer"
        >
          {text.billingPermissions.billingSupportLink}
        </Link>
      </div>

      <div className="d-flex justify-content-center align-items-center my-5">
        <Button
          id="auth-billing-btn"
          color="primary"
          className="text-uppercase p-2 px-3"
          disabled={!requirements.hasGoogleAccount || requirements.agreeToAuthorizeBilling}
          onClick={getToken}
        >
          {text.billingPermissions.billingAuthBtn}
        </Button>

        <Status isSuccess={requirements.agreeToAuthorizeBilling} loading={false} />
      </div>

      <SelectBillingId requirements={requirements} handleRequirements={handleRequirements} />
    </div>
  );
};

export default BillingPermission;
