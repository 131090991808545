import React, { createContext, useEffect } from 'react';
import useAppDispatch from '../hooks/useAppDispatch';
import { getUserInfo } from '../redux/userSlice';

const UserInfoContext = createContext({});

const UserInfoProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (window.document.referrer
      && window.document.referrer.includes(process.env.REACT_APP_PUBLIC_HISE_BASE_URL)) {
      dispatch(getUserInfo());
    }
  }, [window.document.referrer]);

  return (
    <UserInfoContext.Provider value={null}>
      { children }
    </UserInfoContext.Provider>
  );
};

export default UserInfoProvider;
