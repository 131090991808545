import React, { useState } from 'react';
import { Button } from 'hise-components';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAppSelector from '../../../hooks/useAppSelector';
import { isNextRequirementMet } from './helpers';
import type { WorkspaceCreationRequirements } from '../../../@types';

const PreviousButton = ({ step, goPreviousStep }: {
  step: number,
  goPreviousStep: () => void,
}) => {
  if (step <= 0) return <section />;

  return (
    <Button
      id="next-btn"
      color="primary"
      className="text-white"
      onClick={goPreviousStep}
    >
      <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
      {' '}
      Previous
    </Button>
  );
};

const NextButton = ({
  step, nextStep, userType, requirements,
}: {
  step: number,
  nextStep: () => void,
  userType: string,
  requirements: WorkspaceCreationRequirements,
}) => {
  if (step === -1 || step === 4) return null;

  return (
    <Button
      id="next-btn"
      color="primary"
      className="text-white"
      disabled={isNextRequirementMet(step, requirements, userType)}
      onClick={nextStep}
    >
      Next
      {' '}
      <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
    </Button>
  );
};

const NavigationButtons = ({
  step, setStep, requirements, hasWorkspace,
}: {
  step: number,
  setStep: React.Dispatch<React.SetStateAction<number>>,
  requirements: WorkspaceCreationRequirements,
  hasWorkspace: object | undefined
}) => {
  const { userType } = useAppSelector((state) => state.user.userInfo);

  const [previousSteps, setPreviousSteps] = useState([]);

  const nextStep = () => {
    setPreviousSteps((prev) => [...prev, step]);

    if (step === 1) {
      if (hasWorkspace) {
        setStep(-1);
        return;
      }

      if (userType === 'regular') {
        setStep(4);
        return;
      }
    }

    setStep(step + 1);
  };

  const goPreviousStep = () => {
    const lastStepIndex = previousSteps.length - 1;
    const lastStep = previousSteps[(lastStepIndex)];

    setStep(lastStep);
    setPreviousSteps((prev) => prev.slice(0, lastStepIndex));
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <PreviousButton
        step={step}
        goPreviousStep={goPreviousStep}
      />

      <NextButton
        step={step}
        nextStep={nextStep}
        userType={userType}
        requirements={requirements}
      />
    </div>
  );
};

export default NavigationButtons;
