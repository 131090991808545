/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'hise-components';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDataApps } from '../../state/DataAppsContext';
import { useNavbar } from '../../state/NavbarContext';
import NavLinks from './NavLinks';

const PublicationNavigation = () => {
  const {
    isPublication,
    publicationNavItems,
    pathname,
  } = useNavbar();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => setIsOpen(false), [pathname]);

  if (!isPublication) return null;

  const navigation = publicationNavItems.map((item) => ((
    <NavLinks key={item.key} link={item} />
  )));

  return (
    <>
      <nav className="navbar navbar-expand-lg text-dark p-0 d-none d-lg-block">
        <div className="container-fluid justify-content-center text-center">
          <button className="navbar-toggler fs-6" type="button">
            Table of Contents
          </button>
          <div className="collapse navbar-collapse" id="pubNav">
            <ul className="navbar-nav">
              {navigation}
            </ul>
          </div>
        </div>
      </nav>

      <ul className="navbar-nav navbar-light ml-auto d-lg-none">
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="publicationNavigation"
            role="button"
            onClick={toggle}
          >
            Table of Contents
          </a>
          <ul className={`dropdown-menu text-center ${isOpen && 'd-block'} start-50 top-100 translate-middle-x`}>
            {navigation}
          </ul>
        </li>
      </ul>
    </>
  );
};

const MobileLinks = ({
  isAuthenticated, isPublication, isLoading, path,
}: {
  isAuthenticated: boolean,
  isPublication: boolean,
  isLoading: boolean,
  path: string,
}) => {
  const { dataApps } = useDataApps();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => setIsOpen(false), [path]);

  return (
    <li className={`nav-item dropdown ${isPublication ? 'd-lg-block' : 'd-lg-none'}`}>
      <Button
        className="nav-link dropdown-toggle"
        id="navbarDropdown"
        color="link"
        onClick={toggle}
      >
        <FontAwesomeIcon icon={faBars} />
      </Button>

      <ul className={`${isOpen && 'd-block end-0'} dropdown-menu dropdown-menu-end`}>
        {!!dataApps.length && (
          <li>
            <Link className="dropdown-item" to="/data-apps">
              Data Apps
            </Link>
          </li>
        )}
        <li>
          <Link className="dropdown-item" to="/publications">
            Publications
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to="/about">
            About
          </Link>
        </li>
        {!isLoading && (
          isAuthenticated ? (
            <li>
              <Link className="dropdown-item" to="/profile">
                Profile
              </Link>
            </li>
          ) : (
            <li>
              <Link className="dropdown-item" to="/login">
                Login
              </Link>
            </li>
          )
        )}
      </ul>
    </li>
  );
};

const Links = ({ isAuthenticated, isPublication, isLoading }: {
  isAuthenticated: boolean,
  isPublication: boolean,
  isLoading: boolean,
}) => {
  const { dataApps } = useDataApps();

  if (isPublication) return null;

  return (
    <>
      {!!dataApps.length && (
        <li className="nav-item d-none d-lg-block">
          <Link className="nav-link" to="/data-apps">
            Data Apps
          </Link>
        </li>
      )}
      <li className="nav-item d-none d-lg-block">
        <Link className="nav-link" to="/publications">
          Publications
        </Link>
      </li>
      <li className="nav-item d-none d-lg-block">
        <Link className="nav-link" to="/about">
          About
        </Link>
      </li>
      {!isLoading && (
        isAuthenticated ? (
          <li className="nav-item d-none d-lg-block">
            <Link className="nav-link" to="/profile">
              Profile
            </Link>
          </li>
        ) : (
          <li className="nav-item d-none d-lg-block">
            <Link className="nav-link" to="/login">
              Login
            </Link>
          </li>
        )
      )}
    </>
  );
};

const Navigation = () => {
  const {
    pathname, isAuthenticated, isLoading, isPublication,
  } = useNavbar();

  return (
    <nav className={`${isPublication ? 'wide' : 'container'} nav-items`}>
      <Link className="text-decoration-none fw-bold home-link" to="/">
        <h6 className="mb-0">
          {isPublication ? 'HISE' : 'Human Immune System Explorer'}
        </h6>
      </Link>

      <div className="">
        <PublicationNavigation />
      </div>

      <ul className="navbar-nav links navbar-light ml-auto">
        <MobileLinks
          isAuthenticated={isAuthenticated}
          isPublication={isPublication}
          isLoading={isLoading}
          path={pathname}
        />
        <Links
          isAuthenticated={isAuthenticated}
          isPublication={isPublication}
          isLoading={isLoading}
        />
      </ul>
    </nav>
  );
};

const Navbar = () => {
  return (
    <header className="public-navbar navbar navbar-expand-lg navbar-expand-md bd-navbar bg-white">
      <Navigation />
    </header>
  );
};

export default Navbar;
