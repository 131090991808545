import React from 'react';
import { Link } from 'react-router-dom';
import Badges from '../Badges';
import Tags from '../Tags';
import { getHeroImage, getTitle } from './helpers';
import type { Author, DataApp, Publication } from '../../@types';
import './style.scss';

const HeroImage = ({ item }: { item: Publication | DataApp }) => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  const src = getHeroImage(item);

  if (!src) return null;

  return (
    <div className="data-card__image-wrapper">
      <img
        className={`data-card__image ${imageLoaded ? 'loaded' : ''}`}
        onLoad={() => setImageLoaded(true)}
        src={src}
        alt={getTitle(item)}
      />

      {!imageLoaded && (
        <div className="data-card__image-alt" />
      )}
    </div>
  );
};

export const Authors = ({ authorInfo }: { authorInfo: Array<Author> }) => (
  <span className="data-card__authors text-secondary fst-italic">
    {authorInfo
      ? authorInfo.map((a) => [a.firstName, a.middleName, a.lastName, a.suffix]
        .filter(Boolean).join(' '))
        .join(', ').replace(/, ([^,]*)$/, ' and $1')
      : ''}
  </span>
);

const Contents = ({ item }: { item: Publication | DataApp }) => {
  const title = getTitle(item);

  return (
    <div className="data-card__content">
      <HeroImage item={item} />

      <div className="data-card__title">
        <h5>{title}</h5>

        <Tags item={item} />

        {('certificateInfo' in item) && (
          <Badges certInfo={item.certificateInfo} />
        )}

        <Authors authorInfo={item.authorInfo} />
      </div>
    </div>
  );
};

const Card = ({ item }: { item: Publication | DataApp }) => {
  const getHref = () => {
    if ('publicationId' in item) {
      return `/explore/${item.id}`;
    }

    if (('dataAppId' in item) && ('landingPage' in item)) {
      return item.landingPage;
    }

    return '';
  };

  return (
    <div className="data-card" key={item.id}>
      <Link
        to={getHref()}
        className="data-card__link"
      >
        <Contents item={item} />
      </Link>
    </div>
  );
};

const ListCards = ({ items }: {
  items: Array<Publication | DataApp>,
}) => {
  return (
    <div className="hise-data-cards">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-3 g-4 justify-content-center">
        {items.map((item) => (
          <Card key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
};

export default ListCards;
