import React from 'react';
import useAppDispatch from '../../../hooks/useAppDispatch';
import useAppSelector from '../../../hooks/useAppSelector';
import { setSelectedBillingId } from '../../../redux/billingSlice';
import text from '../text.json';
import type { WorkspaceCreationRequirements } from '../../../@types';

export const NewBillingId = ({ requirements, handleRequirements }: {
  requirements: WorkspaceCreationRequirements,
  handleRequirements: Function,
}) => {
  const dispatch = useAppDispatch();
  const billingId = useAppSelector((state) => state.certificateExecution.billingId);

  const disabled = !(
    requirements.agreeToTerms
    && requirements.agreeToPayment
    && requirements.hasGoogleAccount
  );
  const pattern = /[A-Za-z\d]{4,6}-[A-Za-z\d]{4,6}-[A-Za-z\d]{4,6}/;
  const patternSource = pattern.source;

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label htmlFor="newBillingId" className="d-flex align-items-center">
      <input
        id="newBillingId"
        type="text"
        className="w-50"
        placeholder={text.billingIdSelection.newBillingId.input.placeholder}
        required
        pattern={patternSource}
        disabled={disabled}
        title={text.billingIdSelection.newBillingId.input.title}
        minLength={20}
        maxLength={20}
        value={billingId}
        onChange={(e) => {
          handleRequirements('isBillingIdValid', e.target.value.match(pattern));
          dispatch(setSelectedBillingId({ billingId: e.target.value }));
        }}
      />
    </label>
  );
};
