import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'hise-components';
import useAppSelector from '../../hooks/useAppSelector';
import useAppDispatch from '../../hooks/useAppDispatch';
import LoadingSpinner from '../../components/LoadingSpinner';
import { getCertificateData } from './helpers';
import { getUserInfo } from '../../redux/userSlice';
import usePageTitle from '../../hooks/usePageTitle';
import { resetCertificateExecutionState, executionStatuses } from '../../redux/certificateExecutionSlice';
import type { Publication, Certificate } from '../../@types';
import text from './text.json';

const EXECUTION_STATUS_CODE_TO_STRING = {
  0: 'Not Started',
  1: 'In Progress',
  3: 'In Progress', // copy files ready
  7: 'Completed', // clone IDE ready
  15: 'Completed', // run pipelines ready
};

const BillingQuotaReached = () => {
  const { status } = useAppSelector((state) => state.certificateExecution);
  const { billingIdQuotaReached } = status;

  if (!billingIdQuotaReached) return null;

  return (
    <div className="mt-4 border border-3 border-warning p-3">
      <p>
        {text.billingIdQuotaReached.map((sentence) => (
          <>
            {sentence}
            <br />
          </>
        ))}
      </p>

      <Button
        noText
        target="_blank"
        href={`${process.env.REACT_APP_HISE_BASE_URL}/#/profile`}
      >
        Go to Account Profile
      </Button>
    </div>
  );
};

const ProgressBar = ({ progress, inProgressOperations }: {
  progress: number,
  inProgressOperations: Array<string>,
}) => {
  return (
    <>
      <div className="mb-2">
        <span className="fst-italic">
          {text.progressBarLabel}
        </span>

        {!!inProgressOperations?.length && inProgressOperations.join(', ')}
      </div>

      <div className="progress">
        <div
          role="progressbar"
          className="progress-bar progress-bar-striped progress-bar-animated"
          aria-valuenow={progress}
          aria-valuemin={0}
          aria-valuemax={100}
          style={{ width: `${parseInt(String(progress), 10)}%` }}
        />
      </div>
    </>
  );
};

const SetupStatus = () => {
  const { status: setupStatus } = useAppSelector((state) => state.certificateExecution);

  if (!setupStatus) return null;

  const getStatusColor = () => {
    switch (setupStatus?.status) {
      case 7:
      case 15:
        return 'text-success';
      // case executionStatuses.failed:
      //   return 'text-danger';
      default:
        return 'text-ok';
    }
  };

  return (
    <section className="d-flex justify-content-center align-items-center mb-3">
      <h4 className={`d-inline m-0 ${getStatusColor()}`}>
        {EXECUTION_STATUS_CODE_TO_STRING[setupStatus.status]}
      </h4>

      {[0, 1].includes(setupStatus.status) && (
        <div className="spinner-border text-warning ms-3" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
    </section>
  );
};

const WorkspaceKickoffConfirmation = () => {
  usePageTitle('Confirmation: You have successfully started a workspace!');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const {
    status: setupStatus,
    certificateId,
  } = useAppSelector((state) => state.certificateExecution);

  const isWorkspaceReady = executionStatuses.succeeded.includes(setupStatus?.status);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    certificateData: {} as Certificate,
    publicationData: {} as Publication,
  });

  useEffect(() => {
    const init = async () => {
      const d = await getCertificateData(certificateId);
      setData(d);
      dispatch(getUserInfo());
    };

    setLoading(true);
    init();
    setLoading(false);
  }, [certificateId]);

  const calculateProgress = () => {
    if (!setupStatus?.completedOperations?.length) return 0;

    const completedOperations = setupStatus.completedOperations?.length ?? 0;
    const inprogressOperations = setupStatus.inprogressOperations?.length ?? 0;
    const notstartedOperations = setupStatus.notstartedOperations?.length ?? 0;

    const totalOps = completedOperations + inprogressOperations + notstartedOperations;

    return ((completedOperations / totalOps) * 100) || 0;
  };

  const handleGoToWorkspace = () => {
    window.open(process.env.REACT_APP_HISE_BASE_URL, '_blank');
  };

  if (loading || user.isLoading) return <LoadingSpinner />;

  if (!setupStatus) {
    dispatch(resetCertificateExecutionState());
    navigate('/');
  }

  return (
    <div className="my-5 pt-5 text-center container" style={{ minHeight: '60vh' }}>
      <h1 className="mb-2 text-dark">{text.header}</h1>

      <div className="mb-5">
        {text.lead.map((sentence) => (
          <>
            {sentence}
            <br />
          </>
        ))}
      </div>

      <h2>{data.publicationData.title}</h2>

      <div className="mt-5">
        <SetupStatus />
      </div>

      <BillingQuotaReached />

      <div className="mt-5">
        {isWorkspaceReady
          ? (
            <Button
              id="go-to-ws-btn"
              color="success"
              className="hise-btn text-white"
              onClick={handleGoToWorkspace}
            >
              {text.goToWorkspaceBtnLabel}
            </Button>
          ) : (
            <ProgressBar
              progress={calculateProgress()}
              inProgressOperations={setupStatus.inprogressOperations}
            />
          )}
      </div>
    </div>
  );
};

export default WorkspaceKickoffConfirmation;
