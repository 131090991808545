import React from 'react';
import text from './text.json';
import type { WorkspaceCreationRequirements } from '../../@types';

const TermsAndConditions = ({ requirements, onCheck }: {
  requirements: WorkspaceCreationRequirements,
  onCheck: Function,
}) => {
  return (
    <div className="mb-5">
      <h3 className="text-dark">{text.termsAndConditions.header}</h3>

      <label htmlFor="readTerms" className="fst-italic">
        {text.termsAndConditions.agreeCheckLabel[0]}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://alleninstitute.org/terms-of-use/"
        >
          {text.termsAndConditions.agreeCheckLabel[1]}
        </a>

        <input
          id="readTerms"
          type="checkbox"
          className="mx-2 align-middle"
          checked={requirements.agreeToTerms}
          onChange={(e) => onCheck('agreeToTerms', e.target.checked)}
        />
      </label>
    </div>
  );
};

const FeesAndPayment = ({ requirements, onCheck }: {
  requirements: WorkspaceCreationRequirements,
  onCheck: Function,
}) => {
  return (
    <div className="mb-3">
      <h3 className="text-dark">{text.feesAndPayment.header}</h3>

      <p>
        {text.feesAndPayment.agreement}
      </p>

      <label htmlFor="readPayment" className="fst-italic">
        {text.feesAndPayment.agreeCheckLabel}

        <input
          id="readPayment"
          type="checkbox"
          className="mx-2 align-middle"
          checked={requirements.agreeToPayment}
          onChange={(e) => onCheck('agreeToPayment', e.target.checked)}
        />
      </label>
    </div>
  );
};

const TermsAndPaymentForm = (props: {
  requirements: WorkspaceCreationRequirements,
  onCheck: Function,
}) => {
  return (
    <div>
      <TermsAndConditions requirements={props.requirements} onCheck={props.onCheck} />
      <FeesAndPayment requirements={props.requirements} onCheck={props.onCheck} />
    </div>
  );
};

export default TermsAndPaymentForm;
