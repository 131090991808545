import React from 'react';
import text from './text.json';

const BillingSupportHero = () => (
  <div className="mb-5 border-bottom border-3 border-dark">
    <h1 className="text-primary">
      { text.billingSupportHero.header }
    </h1>
    <div className="col-lg-11">
      <p className="lead">
        { text.billingSupportHero.lead }
      </p>
    </div>
  </div>
);

const BillingIdSupport = () => {
  return (
    <div className="container my-5 pb-2">
      <BillingSupportHero />

      <div>
        <div className="mb-5">
          <h3 className="text-dark">
            { text.billingSupportInformation.individualAccounts.header }
          </h3>
          <p>
            { text.billingSupportInformation.individualAccounts.paragraph }
            <a href="https://cloud.google.com/billing/docs/how-to/create-billing-account">
              { text.billingSupportInformation.individualAccounts.billingGuideLinkText }
            </a>
          </p>
        </div>

        <div className="mb-5">
          <h3 className="text-dark">
            { text.billingSupportInformation.existingAccounts.header }
          </h3>
          <p>
            { text.billingSupportInformation.existingAccounts.paragraph }
          </p>
        </div>

        <div className="mb-5">
          <h3 className="text-dark">
            { text.billingSupportInformation.newAccounts.header }
          </h3>
          <p>
            { text.billingSupportInformation.newAccounts.paragraph }
          </p>
        </div>

        <div className="mb-5">
          <h3 className="text-dark">
            { text.billingSupportInformation.NIHGrants.header }
          </h3>
          <p>
            { text.billingSupportInformation.NIHGrants.paragraph }
          </p>
        </div>
      </div>
    </div>
  );
};

export default BillingIdSupport;
