import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { certificateExecutionReducer, pollListenerMiddleware } from './certificateExecutionSlice';
import { userReducer } from './userSlice';
import { billingReducer } from './billingSlice';

const rootReducer = combineReducers({
  certificateExecution: persistReducer({ key: 'certificateExecution', storage }, certificateExecutionReducer),
  user: persistReducer({ key: 'user', storage }, userReducer),
  billing: persistReducer({ key: 'billing', storage }, billingReducer),
});

export const makeStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).prepend(pollListenerMiddleware.middleware);
    },
  });
};

export const store = makeStore();

export const appStorePersistor = persistStore(store);

setupListeners(store.dispatch);
