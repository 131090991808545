import React from 'react';
import type { Certificate } from '../@types';

const getBadge = (cert: Certificate) => {
  const color = cert.isExecutable === 'true' ? 'gold' : 'silver';
  return `/publicationBadges/${cert.publicationElement}_badge_${color}.svg`;
};

const Badges = ({ certInfo }: {
  certInfo: Array<Certificate>,
}) => {
  if (!certInfo || !certInfo.length) return null;

  return (
    <div className="my-3">
      {certInfo.map((cert) => {
        if (!cert.publicationElement) return null;

        return (
          <img
            key={cert.publicationElement}
            alt={`publication badge for ${cert.publicationElement}`}
            src={getBadge(cert)}
            className="me-2"
            width="40px"
          />
        );
      })}
    </div>
  );
};

export default Badges;
