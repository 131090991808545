import type { WorkspaceCreationRequirements } from '../../../@types/index';

export const isNextRequirementMet = (
  step: number,
  requirements: WorkspaceCreationRequirements,
  userType: string,
) => {
  let isConditionMet = false;

  switch (step) {
    case 1:
      isConditionMet = requirements.hasGoogleAccount;
      break;

    case 2:
      isConditionMet = requirements.agreeToTerms && requirements.agreeToPayment;
      break;

    case 3:
      if (userType === 'regular') return false;

      isConditionMet = requirements.agreeToAuthorizeBilling && requirements.isBillingIdValid;
      break;

    case 4:
    case 0:
    default:
      return isConditionMet;
  }

  return !isConditionMet;
};

export const areAllReqsMet = (userType: string, requirements: WorkspaceCreationRequirements) => {
  if (userType === 'regular') return true;

  return Object.values(requirements).every((req) => req);
};
