import React from 'react';
import { getTags } from './getTags';
import type { Publication, DataApp } from '../../@types';

const Tags = ({ item }: { item: Publication | DataApp }) => {
  const tags = getTags(item);

  if (!tags.length) return null;

  return (
    <div className="data-card__tags">
      {tags.map((tag) => (
        <span key={tag.label} className={`badge rounded-pill me-1 bg-${tag.color}`}>{tag.label}</span>
      ))}
    </div>
  );
};

export default Tags;
