import React, { useState } from 'react';
import LoadingSpinner from '../../components/LoadingSpinner';
import { Authors } from '../../components/ListCards';
import Tags from '../../components/Tags';
import usePageTitle from '../../hooks/usePageTitle';
import { useDataApps } from '../../state/DataAppsContext';
import content from './content.json';
import type { Author, DataApp } from '../../@types';
import './style.scss';

const ExpandButton = ({ onClick, isLongList, isShowMore }: {
  onClick: React.MouseEventHandler<HTMLButtonElement>,
  isLongList: boolean,
  isShowMore: boolean,
}) => {
  if (!isLongList) return null;

  return (
    <button
      type="button"
      className="expand-btn text-secondary"
      onClick={onClick}
    >
      {isShowMore ? '\u25B2' : '\u25BC'}
    </button>
  );
};

const DataAppAuthors = ({ authorInfo }: {
  authorInfo: Array<Author>,
}) => {
  const [showMore, setShowMore] = useState(false);
  const isLongList = authorInfo.length > 8;
  const fade = !showMore && isLongList && 'fade-container';

  const onClick = () => setShowMore(!showMore);

  return (
    <div className={`data-apps-card__authors show-${showMore} ${fade}`}>
      <Authors authorInfo={authorInfo} />
      <ExpandButton onClick={onClick} isLongList={isLongList} isShowMore={showMore} />
    </div>
  );
};

export const DataAppCard = ({ dataApp }: {
  dataApp: DataApp,
}) => {
  const heroImgSrc = dataApp.heroImages?.length
    ? dataApp.heroImages[0]
    : 'about-image-1.jpg';

  return (
    <div className="row mb-4 border-bottom">
      <img
        alt={dataApp.name}
        className="col-md-4 mb-3 mb-md-4"
        style={{
          height: '200px',
          objectFit: 'cover',
        }}
        src={heroImgSrc}
      />

      <div className="col-md-8 col-12">
        <h3>
          <a href={dataApp.landingPage}>
            {dataApp.name}
          </a>
        </h3>

        <DataAppAuthors authorInfo={dataApp.authorInfo} />

        <div className="my-3">
          <Tags item={dataApp} />
        </div>

        <p className="mt-3">
          {dataApp.description}
        </p>
      </div>
    </div>
  );
};

const DataAppsList = (): React.ReactNode => {
  usePageTitle('Data Apps - Human Immune System Explorer');

  const { dataApps } = useDataApps();

  return (
    <React.Suspense fallback={<LoadingSpinner />}>
      <div className="my-5 list container">
        <div className="container">
          <h1>{content.header}</h1>
          <h5 className="lead">{content.subheader}</h5>
        </div>

        <div className="container my-5">
          {dataApps.map((dataApp) => (
            <DataAppCard key={dataApp.id} dataApp={dataApp} />
          ))}
        </div>
      </div>
    </React.Suspense>
  );
};

export default DataAppsList;
