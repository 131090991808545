import type { Publication, DataApp } from '../../@types';

export const getTitle = (item: Publication | DataApp) => {
  if ('title' in item) return item.title;
  if ('name' in item) return item.name;
  return '';
};

export const getHeroImage = (item: Publication | DataApp) => {
  if ('publicHeroImages' in item) {
    if (!item.publicHeroImages || !item.publicHeroImages.length) return '';
    return item.publicHeroImages[0].url;
  }

  if ('heroImages' in item) {
    if (!item.heroImages || !item.heroImages.length) return '';
    return item.heroImages[0];
  }

  return '';
};
