/* eslint-disable no-undef */
/* eslint-disable no-console */
/* eslint-disable react/self-closing-comp */
import * as React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {
  ConsoleSpanExporter,
  SimpleSpanProcessor,
} from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { UserInteractionInstrumentation } from '@opentelemetry/instrumentation-user-interaction';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import UserInfoProvider from './state/UserInfoContext';
import PublicationsProvider from './state/PublicationsContext/index';
import DataAppsProvider from './state/DataAppsContext';
import NavbarProvider from './state/NavbarContext';
import Navbar from './components/Navbar/index';
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import LandingPage from './views/LandingPage';
import ActivePublicationFallback from './views/ActivePublicationFallback';
import BillingIdSupport from './views/BillingIdSupport';
import WorkspaceKickoffConfirmation from './views/WorkspaceKickoffConfirmation';
import { appStorePersistor, store } from './redux/store';
import DataAppsList from './views/DataApps/DataAppsList';
import CreateWorkspace from './views/CreateWorkspace';

// Routes
const Contact = React.lazy(() => import('./views/Contact'));
const ContactSuccess = React.lazy(() => import('./views/ContactSuccess'));
const About = React.lazy(() => import('./views/About'));
const Login = React.lazy(() => import('./views/Login'));
const Profile = React.lazy(() => import('./views/Profile'));
const ActivePublication = React.lazy(() => import('./views/ActivePublication'));
const Publications = React.lazy(() => import('./views/Publications'));
const provider = new WebTracerProvider();

provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));

provider.register({
  // Changing default contextManager to use ZoneContextManager
  // supports asynchronous operations - optional
  contextManager: new ZoneContextManager(),
});

// Registering instrumentations
registerInstrumentations({
  instrumentations: [
    new DocumentLoadInstrumentation(),
    new UserInteractionInstrumentation(),
    new XMLHttpRequestInstrumentation(),
  ],
});

const App = (): React.ReactNode => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Provider store={store}>
        <PersistGate persistor={appStorePersistor}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            pauseOnHover
            pauseOnFocusLoss
            draggable
          />
          <PublicationsProvider>
            <DataAppsProvider>
              <BrowserRouter>
                <UserInfoProvider>
                  <NavbarProvider>
                    <div style={{ minHeight: 'calc(100vh - 400px)' }}>
                      <ScrollToTop>
                        <React.Suspense>
                          <Navbar />
                          <Routes>
                            <Route path="/" element={<LandingPage />} />
                            <Route
                              path="/explore/:pubId/*"
                              element={(
                                <ErrorBoundary FallbackComponent={ActivePublicationFallback}>
                                  <ActivePublication />
                                </ErrorBoundary>
                              )}
                            />
                            <Route path="/data-apps" element={<DataAppsList />} />
                            <Route path="/publications/*" element={<Publications />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/contact-success" element={<ContactSuccess />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/support/billing" element={<BillingIdSupport />} />
                            <Route path="/confirmation" element={<WorkspaceKickoffConfirmation />} />
                            <Route path="/workspace/create/:certificateId" element={<CreateWorkspace />} />
                          </Routes>
                        </React.Suspense>
                      </ScrollToTop>
                    </div>
                    <Footer />
                  </NavbarProvider>
                </UserInfoProvider>
              </BrowserRouter>
            </DataAppsProvider>
          </PublicationsProvider>
        </PersistGate>
      </Provider>
    </GoogleOAuthProvider>
  );
};

export default App;
