import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from 'react';
import type { DataApp } from '../@types';

interface DataAppsContext {
  dataApps: Array<DataApp>,
  completed: boolean,
  error: string,
}

export const DataAppsContext = createContext<DataAppsContext>({
  dataApps: [],
  completed: false,
  error: '',
});

const DataAppsProvider = ({ children }: { children: React.ReactNode }) => {
  const [dataApps, setDataApps] = useState([]);
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const getDataApps = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_HISE_BASE_URL}/public/dataapp?visibility=Public&_sort=-lastModified`,
          { method: 'GET', credentials: 'include' },
        );

        const data = await response.json();

        if (response.ok && data) setDataApps(data);
      } catch {
        setError('Unexpected Error: Please try again at a later time.');
      }

      setCompleted(true);
    };

    getDataApps();
  }, []);

  const contextValue = useMemo(() => ({
    dataApps,
    completed,
    error,
  }), [
    dataApps,
    completed,
    error,
  ]);

  return (
    <DataAppsContext.Provider value={contextValue}>
      {children}
    </DataAppsContext.Provider>
  );
};

export const useDataApps = () => useContext(DataAppsContext);

export default DataAppsProvider;
