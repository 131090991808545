import * as R from 'ramda';
import type { Publication, DataApp } from '../../@types';

export const getTags = (item: Publication | DataApp) => {
  const tags = [];

  if (R.isNil(item) || R.isEmpty(item)) return tags;

  if (
    ('certificateInfo' in item)
    && !R.isNil(item.certificateInfo)
    && !R.isEmpty(item.certificateInfo)
  ) {
    tags.push({
      label: 'Certificate of Reproducibility',
      color: 'primary',
    });
  }

  if (!R.isEmpty(item.fileSets)) {
    tags.push({
      label: 'File Set',
      color: 'secondary',
    });
  }

  if (!R.isEmpty(item.visualizations)) {
    tags.push({
      label: 'Visualization',
      color: 'secondary',
    });
  }

  return tags;
};
