export const getCertificateData = async (certId: string) => {
  const certResponse = await fetch(
    `${process.env.REACT_APP_HISE_BASE_URL}/public/certificate?certificateId=${certId}`,
    { method: 'GET', credentials: 'include' },
  );

  const certificateData = await certResponse.json();

  const pubResponse = await fetch(
    `${process.env.REACT_APP_HISE_BASE_URL}/public/publication?publicationId=${certificateData[0].releaseId}`,
    { method: 'GET', credentials: 'include' },
  );

  const publicationData = await pubResponse.json();

  return {
    certificateData: certificateData[0],
    publicationData: publicationData[0],
  };
};
