import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';
import useAppSelector from '../../hooks/useAppSelector';
import Status from '../../components/Guest/ReproduceCertificate/Status';
import { setCredentials, setUserInfo, removeGoogleAccessToken } from '../../redux/userSlice';
import { resetAvailableBillingIds, setSelectedBillingId } from '../../redux/billingSlice';
import text from './text.json';
import type { WorkspaceCreationRequirements } from '../../@types';

const userInfoUrl = 'publication/workspace/userInfo';

const WelcomeBanner = ({ hasGoogleAccount }: { hasGoogleAccount: boolean }) => {
  const email = useAppSelector((state) => state.user.userInfo.email);

  if (!hasGoogleAccount) return null;

  return (
    <h6 className="text-center">
      You have signed in using
      {' '}
      {email}
      !
    </h6>
  );
};

const GoogleAuthentication = ({ handleRequirements, requirements }: {
  requirements: WorkspaceCreationRequirements,
  handleRequirements: Function,
}) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleLoginSuccess = async (loginResponse) => {
    setLoading(true);

    await fetch(
      `${process.env.REACT_APP_PUBLIC_HISE_BASE_URL}/${userInfoUrl}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Proxy-Authorization': `Bearer ${loginResponse.clientId}`,
          Authorization: `Bearer ${loginResponse.credential}`,
        },
      },
    ).then(async (response) => {
      const userInfo = await response.json();
      dispatch(setUserInfo({ userInfo }));
    });

    handleRequirements('hasGoogleAccount', true);
    dispatch(setCredentials({ credentials: loginResponse }));
    dispatch(removeGoogleAccessToken());
    dispatch(resetAvailableBillingIds());
    dispatch(setSelectedBillingId({ billingId: '' }));

    setLoading(false);
  };

  return (
    <>
      <h3>{text.googleAuthentication.header}</h3>

      <div>
        {text.googleAuthentication.subheader}
      </div>

      <div className="d-flex flex-column align-items-center">
        <div className="d-flex align-items-center my-5">
          <GoogleLogin onSuccess={handleLoginSuccess} />
          <Status isSuccess={requirements.hasGoogleAccount} loading={loading} />
        </div>

        <WelcomeBanner hasGoogleAccount={requirements.hasGoogleAccount} />
      </div>
    </>
  );
};

export default GoogleAuthentication;
