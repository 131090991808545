import { useState, useEffect } from 'react';

export default (_title: string = 'Human Immune System Explorer'): {
  setTitle: React.Dispatch<React.SetStateAction<string>>,
} => {
  const [title, setTitle] = useState(_title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return {
    setTitle,
  };
};
