import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
} from 'react';
import { getGroups } from './helpers';
import config from './config.json';
import type { Publication } from '../../@types';

type PublicationsContextType = {
  publications: {
    all: Array<Publication>,
    certificates: Array<Publication>,
    visualizations: Array<Publication>,
    fileSets: Array<Publication>,
  },
  completed: boolean,
  error: string,
  config: {
    [label: string]: {
      url: string,
      heroLabel: string,
      label: string,
    },
  },
};

export const PublicationsContext = createContext<PublicationsContextType>({
  publications: {
    all: [],
    certificates: [],
    visualizations: [],
    fileSets: [],
  },
  completed: false,
  error: '',
  config: {},
});

const PublicationsProvider = ({ children }: { children: React.ReactNode }) => {
  const [publications, setPublications] = useState({
    all: [],
    certificates: [],
    visualizations: [],
    fileSets: [],
  });
  const [completed, setCompleted] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const getPubList = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_HISE_BASE_URL}/public/publication?visibility=Public&_sort=-auditInfo.lastModified`,
          { method: 'GET', credentials: 'include' },
        );

        let data = await response.json();

        if (response.ok) {
          if (!data) data = []; // API returns null for empty list

          const groups = getGroups(data);

          setPublications(groups);
        } else {
          setError(`${data.Status}: ${data.Message}`);
        }
      } catch {
        setError('Unexpected Error: Please try again at a later time.');
      }

      setCompleted(true);
    };

    getPubList();
  }, []);

  const contextValue = useMemo(() => ({
    publications,
    completed,
    error,
    config,
  }), [
    publications,
    completed,
    error,
  ]);

  return (
    <PublicationsContext.Provider value={contextValue}>
      {children}
    </PublicationsContext.Provider>
  );
};

export const usePublications = () => useContext(PublicationsContext);

export default PublicationsProvider;
