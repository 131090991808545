import * as R from 'ramda';
import type { Publication } from '../../@types';

export const getGroups = (publications: Array<Publication>) => {
  const groups = {
    all: [],
    certificates: [],
    visualizations: [],
    fileSets: [],
  };

  if (R.isNil(publications) || R.isEmpty(publications)) return groups;

  groups.all = publications;

  publications.forEach((publication) => {
    const { certificateInfo, fileSets, visualizations } = publication;

    if (!R.isNil(certificateInfo) && !R.isEmpty(certificateInfo)) {
      groups.certificates.push(publication);
    }

    if (!R.isEmpty(visualizations)) {
      groups.visualizations.push(publication);
    }

    if (!R.isEmpty(fileSets)) {
      groups.fileSets.push(publication);
    }
  });

  return groups;
};
