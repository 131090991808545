import React, {
  createContext, useEffect, useState, useContext, useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import useAppSelector from '../hooks/useAppSelector';

export type LinkItem = {
  key: string,
  label: string,
  isDropdown?: boolean,
  dropdownItems?: Array<LinkItem>,
};

type NavBarContextType = {
  pathname: string,
  isAuthenticated: boolean,
  isLoading: boolean,
  isPublication: boolean,
  publicationNavItems: Array<LinkItem>,
  setPublicationNavItems: React.Dispatch<React.SetStateAction<Array<LinkItem>>>,
};

export const NavbarContext = createContext<NavBarContextType>({
  pathname: '',
  isAuthenticated: false,
  isLoading: false,
  isPublication: false,
  publicationNavItems: [],
  setPublicationNavItems: null,
});

const NavbarProvider = ({ children }: {
  children: React.ReactNode,
}) => {
  const { pathname } = useLocation();

  const [isPublication, setIsPublication] = useState(false);
  const [publicationNavItems, setPublicationNavItems] = useState([]);

  const { isAuthenticated, isLoading } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (pathname.match(/\/explore\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gm)) {
      setIsPublication(true);
    } else {
      setPublicationNavItems([]);
      setIsPublication(false);
    }
  }, [pathname]);

  const value = useMemo(() => ({
    pathname,
    isAuthenticated,
    isLoading,
    isPublication,
    publicationNavItems,
    setPublicationNavItems,
  }), [
    pathname,
    isAuthenticated,
    isLoading,
    isPublication,
    publicationNavItems,
    setPublicationNavItems,
  ]);

  return (
    <NavbarContext.Provider value={value}>
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbar = () => useContext(NavbarContext);

export default NavbarProvider;
