import { useSelector, type TypedUseSelectorHook } from 'react-redux';
import type { Workspace } from '../@types';

export interface UserState {
  isLoading: boolean,
  userInfo: {
    userId: string,
    isRecognized: boolean,
    userType: string,
    email: string,
    workspaces: Array<Workspace>,
  },
  isAuthenticated: boolean,
  credentials: {
    credential: string,
    clientId: string,
  },
  accountId: string,
  googleAccessToken: string,
}

export interface CertificateExecutionState {
  certificateId: string,
  workspaceId: string,
  billingId: string,
  status: {
    status: number,
    message: string,
    completedOperations: [],
    inprogressOperations: [],
    notstartedOperations: [],
    billingIdQuotaReached: boolean,
  },
  isLoading: boolean,
  errors: Array<unknown>,
}

export interface BillingId {
  displayName: string,
  masterBillingAccount: string,
  name: string,
  open: boolean,
  parent: string,
}

export interface BillingState {
  isLoading: boolean,
  errors: Array<unknown>,
  availableBillingIds: Array<BillingId>,
  selectedBillingId: string,
}

export interface AppState {
  user: UserState,
  billing: BillingState,
  certificateExecution: CertificateExecutionState,
}

const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export default useAppSelector;
