import React from 'react';
import { faTimesCircle, faCircleCheck, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Status = ({ isSuccess, loading }: {
  isSuccess: boolean,
  loading: boolean,
}) => {
  if (loading) return <FontAwesomeIcon icon={faCircleNotch} className="ms-2 fa-spin" />;

  return (
    <FontAwesomeIcon
      icon={isSuccess ? faCircleCheck : faTimesCircle}
      size="lg"
      className={`ms-2 ${isSuccess ? 'text-success' : 'text-warning'}`}
    />
  );
};

export default Status;
