/* eslint-disable no-await-in-loop */
/* eslint-disable no-use-before-define */
/* eslint-disable no-param-reassign */
import {
  createAction, createAsyncThunk, createListenerMiddleware, createSlice,
} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { sleep } from '../utils';
import { setUserId, setAccountId } from './userSlice';
import { AppState, CertificateExecutionState } from '../hooks/useAppSelector';

export const executionStatuses = {
  notStarted: 0,
  inProgress: 1,
  succeeded: [7, 15], // copy files and start ide ready
  // failed: 3,
};

const internalInitialState: CertificateExecutionState = {
  certificateId: '',
  workspaceId: '',
  billingId: '',
  status: {
    status: 0,
    message: '',
    completedOperations: [],
    inprogressOperations: [],
    notstartedOperations: [],
    billingIdQuotaReached: false,
  },
  isLoading: false,
  errors: [],
};

const certificateExecutionSlice = createSlice({
  name: 'certificateExecution',
  initialState: internalInitialState,
  reducers: {
    resetCertificateExecutionState: () => {
      return internalInitialState;
    },
    setWorkspaceStatus: (state, action) => {
      const { status } = action.payload;
      state.status = status;
    },
    setWorkspaceId: (state, action) => {
      const { workspaceId } = action.payload;
      state.workspaceId = workspaceId;
      state.status = internalInitialState.status;
    },
    setCertificateId: (state, action) => {
      const { certificateId } = action.payload;
      state.certificateId = certificateId;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(provisionWorkspace.fulfilled, (state) => {
      state.status.status = 1;
      state.isLoading = true;
    });
    builder.addCase(executeCertificate.fulfilled, (state) => {
      state.status.status = 1;
    });
    builder.addCase(executeCertificate.rejected, (state) => {
      state.isLoading = false;
      toast('Failed to complete setup for workspace of this publication. Please try again');
    });
    builder.addCase(pollWorkspaceProvisionStatus.fulfilled, (state, action) => {
      if (action.payload) {
        state.status = action.payload;
      }
    });
    builder.addCase(pollWorkspaceProvisionStatus.rejected, (state, action) => {
      state.errors.push(action.error);
    });
    builder.addCase(eventProvisionWorkspaceStopped, (state) => {
      state.isLoading = false;
    });
  },
});

const pollWorkspaceProvisionStatus = createAsyncThunk(
  'pollWorkspaceProvisionStatus',
  async (_, { dispatch, getState }) => {
    const { certificateExecution, user } = getState() as AppState;
    const { workspaceId } = certificateExecution;
    const { credentials } = user;

    const pollUrl = `publication/workspace/provision/${workspaceId}/status`;

    try {
      const pollResponse = await fetch(
      /* $FlowIgnore[incompatible-type] */
        `${process.env.REACT_APP_PUBLIC_HISE_BASE_URL}/${pollUrl}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Proxy-Authorization': `Bearer ${credentials.clientId}`,
            Authorization: `Bearer ${credentials.credential}`,
          },
        },
      );

      const status = await pollResponse.json();

      if (executionStatuses.succeeded.includes(status.status)) {
        dispatch(eventProvisionWorkspaceStopped());
        toast('Workspace provisioned successfully!');
      }
      // if (executionStatuses.failed === status.status) {
      //   dispatch(eventProvisionWorkspaceStopped());
      //   toast(`Workspace provisioning has failed. Error: ${status.message}`);
      // }
      return status;
    } catch (error) {
      toast(error);
    }

    return false;
  },
);

const provisionWorkspace = createAsyncThunk(
  'provisionWorkspace',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const { certificateExecution, user } = getState() as AppState;
    const { workspaceId } = certificateExecution;
    const { accountId, userInfo, credentials } = user;

    const provisionWorkspaceUrl = `publication/workspace/provision/${workspaceId}?accountId=${accountId}`;
    const response = await fetch(
      `${process.env.REACT_APP_PUBLIC_HISE_BASE_URL}/${provisionWorkspaceUrl}`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Proxy-Authorization': `Bearer ${credentials.clientId}`,
          Authorization: `Bearer ${credentials.credential}`,
        },
        body: JSON.stringify({ userId: userInfo.userId }),
      },
    );

    if (response && !response.ok) {
      const data = await response.json();

      dispatch(eventProvisionWorkspaceStopped());
      throw rejectWithValue(`Provision workspace failed: ${data.Message}`);
    }

    return true;
  },
);

const prepareWorkspace = createAsyncThunk(
  'prepareWorkspace',
  async (_, { getState, rejectWithValue }) => {
    const { certificateExecution, billing, user } = getState() as AppState;
    const { certificateId } = certificateExecution;
    const { selectedBillingId } = billing;
    const { credentials } = user;

    const prepareWorkspaceUrl = `publication/workspace/prepare?certificateId=${certificateId}&certificateType=Public`;
    const response = await fetch(
      `${process.env.REACT_APP_PUBLIC_HISE_BASE_URL}/${prepareWorkspaceUrl}`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Proxy-Authorization': `Bearer ${credentials.clientId}`,
          Authorization: `Bearer ${credentials.credential}`,
        },
        body: JSON.stringify({ billingId: selectedBillingId }),
      },
    );

    const data = await response.json();

    if (!response.ok) {
      throw rejectWithValue(`${data.Status}: ${data.Message}`);
    }

    return data;
  },
);

export const executeCertificate = createAsyncThunk(
  'executeCertificate',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { projectId, accountId, userId } = await dispatch(prepareWorkspace()).unwrap();

      dispatch(setWorkspaceId({ workspaceId: projectId }));
      dispatch(setAccountId({ accountId }));
      dispatch(setUserId({ userId }));

      const response = await dispatch(provisionWorkspace()).unwrap();

      dispatch(eventProvisionWorkspaceStarted());

      return response;
    } catch (error) {
      toast.error(error);

      throw rejectWithValue(error);
    }
  },
);

export const pollListenerMiddleware = createListenerMiddleware();

const eventProvisionWorkspaceStarted = createAction('provisionWorkspace/started');
const eventProvisionWorkspaceStopped = createAction('provisionWorkspace/stopped');

pollListenerMiddleware.startListening({
  predicate: (action, currentState: AppState) => {
    return currentState.certificateExecution.isLoading;
  },
  effect: async (action, listenerApi) => {
    listenerApi.unsubscribe();

    const pollingTask = listenerApi.fork(async (forkApi) => {
      // eslint-disable-next-line no-constant-condition
      while (true) { // task will get cancelled when stop polling event is fired
        await sleep(1000 * 30); // 30 seconds
        await forkApi.pause(listenerApi.dispatch(pollWorkspaceProvisionStatus()));
      }
    });

    await listenerApi.condition(eventProvisionWorkspaceStopped.match);
    pollingTask.cancel();
    listenerApi.subscribe();
  },
});

export const {
  resetCertificateExecutionState,
  setWorkspaceId,
  setCertificateId,
  setWorkspaceStatus,
} = certificateExecutionSlice.actions;

export const certificateExecutionReducer = certificateExecutionSlice.reducer;
