/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import { LinkItem } from '../../state/NavbarContext';

type Navigation = {
  link: LinkItem,
  activeClass: Function,
};

const DropdownItem = ({ link, activeClass }: Navigation) => (
  <li key={`${link.key}-li`}>
    <Link
      className={`dropdown-item ${activeClass(link.key)}`}
      to={link.key}
    >
      {link.label}
    </Link>
  </li>
);

const NavItem = ({ link, activeClass }: Navigation) => (
  <li className="nav-item" key={link.key}>
    <Link
      className={`nav-link ${activeClass(link.key)}`}
      to={link.key}
    >
      {link.label}
    </Link>
  </li>
);

const Dropdown = ({ link, activeClass }: Navigation) => (
  <li className="nav-item dropdown" key={`${link.label}-dropdown`}>
    <a
      className={`nav-link dropdown-toggle ${activeClass(link.key)}`}
      href="#"
      id="navbarDropdown"
      role="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {link.label}
    </a>
    <ul className="dropdown-menu start-50" aria-labelledby="navbarDropdown">
      {link.dropdownItems.map((item) => (
        <DropdownItem key={item.key} link={item} activeClass={activeClass} />
      ))}
    </ul>
  </li>
);

const NavLinks = ({ link, activeView }: {
  link: LinkItem,
  activeView?: string,
}) => {
  const activeClass = (k: string) => {
    if (k === activeView) return 'opacity-100 fw-bold';
    return 'opacity-50';
  };

  if (link.isDropdown) {
    return <Dropdown link={link} activeClass={activeClass} />;
  }

  return <NavItem link={link} activeClass={activeClass} />;
};

export default NavLinks;

NavLinks.defaultProps = {
  activeView: '',
};
